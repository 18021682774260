import { useEffect, useState } from 'react';

import { Avatar, List, Spin } from 'antd';

import { axiosMchsInstance } from '@libs/http';
import { ILayer } from '@modules/Map/domain/interface/interface';
import { setMarkerIcon } from '@modules/Map/domain/store/constans';

export const LegendMap = () => {
  const [legendList, setLegendList] = useState<{ title: string }[]>([]);
  const [legendListLoading, setLegendListLoading] = useState(false);

  const fetchLayerOpp = async () => {
    try {
      setLegendListLoading(true);
      const { data } = await axiosMchsInstance.get<ILayer[] | any[]>(
        '/opp/layers/opp_type',
      );

      const dataMutation = (data as { title: string }[]).map((item) => ({
        title: item.title,
      }));

      setLegendList(dataMutation);
    } catch (e) {
      setLegendListLoading(false);
    } finally {
      setLegendListLoading(false);
    }
  };

  useEffect(() => {
    fetchLayerOpp().catch(console.error);
  }, []);

  return !legendListLoading ? (
    <div style={{ overflowX: 'scroll', height: '400px' }}>
      <List
        itemLayout="horizontal"
        dataSource={legendList}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{ backgroundColor: '#ddd' }}
                  src={setMarkerIcon(item.title)}
                />
              }
              description={<p>{item.title}</p>}
            />
          </List.Item>
        )}
      />
    </div>
  ) : (
    <Spin />
  );
};
