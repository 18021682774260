import Landslides from '@assets/icons/map/avalanche.svg';
import Earthquakes from '@assets/icons/map/free-icon-earthquake-708361.svg';
import Icy from '@assets/icons/map/free-icon-ice-5847838.svg';
import SatDown from '@assets/icons/map/free-icon-river-8992020.svg';
import Karstovo from '@assets/icons/map/hole-_1_.svg';
import iconLeaflet from '@assets/icons/map/iconLeaflet.png';
import Breakthrough from '@assets/icons/map/lake-_4_.svg';
import Collapses from '@assets/icons/map/landslide.svg';
import Geocryological from '@assets/icons/map/snowflake.svg';
import Drawdowns from '@assets/icons/map/soil-_2_.svg';
import Flooding from '@assets/icons/map/tide.svg';

export const getInspectionTypes = (name: string) => {
  if (!name) {
    return 'Другое';
  }

  if (name === 'Operational') {
    return 'Оперативное';
  }

  if (name === 'Planing') {
    return 'Плановое';
  }

  if (name === 'Appeal') {
    return 'Обращение';
  }

  if (name === 'Other') {
    return 'Другое';
  }

  return 'Другое';
};

export const setMarkerIcon = (type: string) => {
  if (!type) {
    return iconLeaflet;
  }

  if (type.includes('Землетрясения')) {
    // Землетрясения
    return Earthquakes;
  }
  if (type.includes('Оползни')) {
    // Оползни
    return Landslides;
  }
  if (type.includes('Сели, паводки и береговая эрозия')) {
    // Сели, паводки и береговая эрозия
    return SatDown;
  }
  if (type.includes('Прорывы плотин высокогорных естественных озер')) {
    // Прорывы плотин высокогорных естественных озер
    return Breakthrough;
  }
  if (type.includes('Обвалы, камнепады')) {
    // Обвалы, камнепады
    return Collapses;
  }
  if (type.includes('Просадки в лессовых и глинистых грунтах')) {
    // Просадки в лессовых и глинистых грунтах
    return Drawdowns;
  }
  if (type.includes('Подтопления, повышение уровня грунтовых вод')) {
    // Подтопления, повышение уровня грунтовых вод
    return Flooding;
  }
  if (type.includes('Геокриологические процессы')) {
    // Геокриологические процессы
    return Geocryological;
  }
  if (type.includes('Карстово-суффозионные процессы')) {
    // Карстово-суффозионные процессы
    return Karstovo;
  }
  if (type.includes('Ледяные заторы и зажоры')) {
    // Ледяные заторы и зажоры
    return Icy;
  }

  return iconLeaflet;
};
