import dayjs, { PluginFunc } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

const customFormatPlugin: PluginFunc = (_, dayjsClass) => {
  const oldFormat = dayjsClass.prototype.format;
  dayjsClass.prototype.format = function (formatString?: string): string {
    const formatToUse =
      formatString && formatString.trim() ? formatString : 'YYYY-MM-DD';
    return oldFormat.bind(this)(formatToUse);
  };
};

dayjs.extend(customFormatPlugin);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Bishkek');

const dayjsCustom = (...args: Parameters<typeof dayjs>) => dayjs(...args).tz();

export default dayjsCustom;
