import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from 'antd';

import { dashboardsList } from '@modules/monitoring/contants/dashboardContants/dashboardContants';
import { SectionTitle } from '@modules/sectionTitle';

export const Dashboards = () => {
  const { t } = useTranslation();
  return (
    <div className="mb-[20px]">
      <SectionTitle title={t('generalWeatherDashboard.title')} />
      <iframe
        style={{ margin: '20px 0' }}
        src="https://dashboard.isoc.kg/public-dashboards/a170a1760d5b4d889b4068671922d6d0"
        title="Общий метеодашборд"
        height="500px"
        width="100%"
      />

      <SectionTitle title={t('monitoring.groundSensors')} />
      <iframe
        style={{ margin: '20px 0' }}
        src="https://dashboard.isoc.kg/public-dashboards/3eab73ff30f5461e84414d3592fe1f1d"
        title="Общий метеодашборд"
        height="500px"
        width="100%"
      />
      <SectionTitle title={t('monitoring.weatherDashboardsByRegion')} />
      {dashboardsList.map((item, index) => (
        <Collapse key={item.dashboard} style={{ margin: '20px 0' }}>
          <Collapse.Panel header={item.name} key={`${index + 1}`}>
            <iframe
              src={item.dashboard}
              title={item.name}
              height="500px"
              width="100%"
            />
          </Collapse.Panel>
        </Collapse>
      ))}
      <SectionTitle title={t('monitoring.healthAndEducation')} />
      <iframe
        style={{ margin: '20px 0' }}
        title="Health and Education"
        width="1266"
        height="600"
        src="https://arcgis-hmfd.okm.kg/portal/apps/opsdashboard/index.html#/672944b0e2264b81b2f23e498bdb4e90"
      />
    </div>
  );
};
