import { memo, useMemo } from 'react';

import { Button, Col, List, Row, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import dayjsCustom from '@config/configuredDayjs';
import { getInspectionTypes } from '@modules/Map/domain/store/constans';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { MapStateSelector } from '../domain/store/selector';
import { removeCurrentGeoData } from '../domain/store/slice';

const flattenArray = (arr: any[]): any[] => {
  const actualItems: any = [];

  arr.forEach((item) => {
    if (Array.isArray(item)) {
      actualItems.push(...flattenArray(item));
    } else {
      actualItems.push(item);
    }
  });

  return actualItems;
};

export const MapGeoDataController = memo(() => {
  const dispatch = useAppDispatch();
  const { currentGeoDate } = useAppSelector(MapStateSelector);

  const hasGeoData = typeof currentGeoDate?.properties !== 'undefined';

  const geoDataProperties = currentGeoDate?.properties;
  const geoDataGeometry = currentGeoDate?.geometry;

  const dataSource = useMemo(() => {
    return geoDataProperties
      ? [
          {
            name: `ОПП: ${geoDataProperties.type.title || 'Нет данных'}`,
          },
          {
            name: `Код: ${geoDataProperties.code || 'Нет данных'}`,
          },
          {
            name: `Дата проишествия: ${
              geoDataProperties.incidentDate
                ? dayjs(geoDataProperties.incidentDate).format('YYYY-MM-DD')
                : 'Нет данных'
            }`,
          },
          {
            name: `Дата обследования: ${
              geoDataProperties.date
                ? dayjs(geoDataProperties.date).format('YYYY-MM-DD')
                : 'Нет данных'
            }`,
          },
          {
            name: `Область: ${geoDataProperties.region.title || 'Нет данных'}`,
          },
          {
            name: `Район: ${geoDataProperties.district.title || 'Нет данных'}`,
          },
          {
            name: `Населённый пункт: ${
              geoDataProperties.locality?.title || 'Нет данных'
            }`,
          },
          {
            name: `Участок: ${geoDataProperties.area || 'Нет данных'}`,
          },
          {
            name: `Описание ОПП: ${
              geoDataProperties.descriptionOpp || 'Нет данных'
            }`,
          },
          {
            name: `Основание ОПП: ${
              getInspectionTypes(geoDataProperties.inspectionType) ||
              'Нет данных'
            }`,
          },
        ]
      : [];
  }, [geoDataProperties]);

  const getCoordiantes = useMemo(() => {
    if (typeof geoDataGeometry?.coordinates === 'undefined') return [];

    const items = flattenArray(geoDataGeometry.coordinates);

    const formatItems = [];

    for (let i = 0; i < items.length; i += 2) {
      formatItems.push({ lat: items[i], lng: items[i + 1] });
    }

    return formatItems;
  }, [geoDataGeometry]);

  const closeController = () => {
    dispatch(removeCurrentGeoData());
  };

  // TODO: Аймак нет, Краткое описание нет

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasGeoData ? (
        <div
          style={{
            width: '300px',
            background: 'white',
            zIndex: 10000,
            position: 'absolute',
            overflowX: 'hidden',
            overflow: 'auto',
            right: 0,
            margin: '2px',
            minHeight: '400px',
          }}
        >
          <List
            header={
              <Row align="middle" justify="space-between">
                <Col>
                  <p>Карточка ОПП</p>
                </Col>
                <Col>
                  <Button
                    onClick={closeController}
                    type="text"
                    icon={<CloseOutlined />}
                  />
                </Col>
              </Row>
            }
            bordered
            dataSource={dataSource}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text mark /> {item.name}
              </List.Item>
            )}
          />
        </div>
      ) : null}
    </>
  );
});
