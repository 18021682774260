import { useParams } from '@hooks/useParams/useParams';
import { axiosInstance, axiosMchsInstance } from '@libs/http';
import { IDictionary, MapState } from '@modules/Map/domain/interface/interface';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: MapState = {
  currentLayer: null,
  currentGeoDate: {},
  layers: [],
  geo: [],
  onLayer: {},
  mapCounts: [],
  previewCmbLayers: [],
  cmbLayers: [],
  district: [],
  region: [],
  countLayer: 0,
  loading: false,
};

export const getDistrict = createAsyncThunk<IDictionary[], number>(
  'MapSlice/getDistrict',
  // eslint-disable-next-line consistent-return
  async (parentId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/dictionary/by-parent-id?parent=${parentId}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getRegions = createAsyncThunk<IDictionary[]>(
  'MapSlice/getRegions',
  // eslint-disable-next-line consistent-return
  async (_: any, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('/dictionary/region');

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getLayers = createAsyncThunk(
  'MapSlice/getLayers',
  // eslint-disable-next-line consistent-return
  async ({ type }: { type: string }, { rejectWithValue }) => {
    try {
      const { data } = await axiosMchsInstance.get(`/opp/layers/${type}`);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getMapCounts = createAsyncThunk(
  'MapSlice/getMapCounts',
  // eslint-disable-next-line consistent-return
  async (_: any) => {
    try {
      const { data } = await axiosMchsInstance.get('/opp/layers/count');
      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getAllCmbLayers = createAsyncThunk(
  'MapSlice/getAllCmbLayers',
  // eslint-disable-next-line consistent-return
  async function (
    params: {
      page: number;
      perPage: number;
      district: string;
      region: string;
    },
    { rejectWithValue },
  ) {
    try {
      const p = useParams(params);

      const { data } = await axiosInstance.get(`/layers${p}`);

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getCmbLayers = createAsyncThunk(
  'MapSlice/getCmbLayers',
  // eslint-disable-next-line consistent-return
  async function (
    params: {
      page: number;
      perPage: number;
      district?: string;
      region?: string;
    },
    { rejectWithValue },
  ) {
    try {
      const p = useParams(params);

      const { data } = await axiosInstance.get(`/layers${p}`);
      return data;
    } catch (error: any) {
      rejectWithValue(error.response.data.message);
    }
  },
);

export const getGeoData = createAsyncThunk(
  'MapSlice/getGeoData',
  // eslint-disable-next-line consistent-return
  async ({
    type,
    id,
    status,
    date,
  }: {
    type: string;
    id: string;
    status: string;
    date?: Record<string, any>;
  }) => {
    const filtered = date ? useParams(date) : useParams({ status });
    try {
      const { data } = await axiosMchsInstance.get(
        `/opp/geo/${type}/${id}${filtered}`,
      );

      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);

const MapSlice = createSlice({
  name: 'MapSlice',
  initialState,
  reducers: {
    removeCurrentGeoData: (state) => {
      state.currentGeoDate = null;
    },
    changeCurrentGeoData: (state, { payload }) => {
      state.currentGeoDate = payload;
    },
    changeCurrentLayer: (state, { payload }) => {
      state.currentLayer = payload;
    },
    changeOnLayer: (state, { payload }) => {
      state.onLayer = { ...state.onLayer, ...payload };
    },
    resetOnLayer: (state) => {
      state.onLayer = {};
    },
    removeOnLayer: (state, { payload }) => {
      delete state.onLayer[payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLayers.fulfilled, (state, action) => {
        state.layers = action.payload;
      })
      .addCase(getLayers.rejected, (state) => {
        state.layers = [];
      });
    builder
      .addCase(getGeoData.fulfilled, (state, action) => {
        state.geo = action.payload;
      })
      .addCase(getGeoData.rejected, (state, action) => {
        state.geo = [];
      });
    builder
      .addCase(getAllCmbLayers.fulfilled, (state, action) => {
        state.cmbLayers = action.payload.content;
      })
      .addCase(getAllCmbLayers.rejected, (state) => {
        state.cmbLayers = [];
      });
    builder
      .addCase(getCmbLayers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCmbLayers.fulfilled, (state, action) => {
        state.previewCmbLayers = action.payload.content;
        state.cmbLayers = action.payload.content;
        state.countLayer = action.payload.count;
        state.loading = false;
      })
      .addCase(getCmbLayers.rejected, (state) => {
        state.previewCmbLayers = [];
        state.cmbLayers = [];
        state.loading = false;
      });
    builder
      .addCase(getMapCounts.fulfilled, (state, action) => {
        state.mapCounts = action.payload;
      })
      .addCase(getMapCounts.rejected, (state) => {
        state.mapCounts = [];
      });
    builder
      .addCase(
        getRegions.fulfilled,
        (state, action: PayloadAction<IDictionary[]>) => {
          if (!Array.isArray(action.payload)) {
            state.region = [];
          } else {
            state.region = action.payload.map((item) => ({
              value: item.id,
              label: item.value,
            }));
          }
        },
      )
      .addCase(getRegions.rejected, (state) => {
        state.region = [];
      });
    builder
      .addCase(
        getDistrict.fulfilled,
        (state, action: PayloadAction<IDictionary[]>) => {
          if (!Array.isArray(action.payload)) {
            state.district = [];
          } else {
            state.district = action.payload.map((item) => ({
              value: item.id,
              label: item.value,
            }));
          }
        },
      )
      .addCase(getDistrict.rejected, (state) => {
        state.district = [];
      });
  },
});

export const {
  changeOnLayer,
  resetOnLayer,
  removeOnLayer,
  changeCurrentGeoData,
  changeCurrentLayer,
  removeCurrentGeoData,
} = MapSlice.actions;
export default MapSlice.reducer;
