import React, { useEffect } from 'react';

import { CollapseProps, Tabs } from 'antd';

import { LegendMap } from '@modules/Map/ui/LegendMap';
import { MapGeoDataController } from '@modules/Map/ui/MapGeoDataController';
import L from 'leaflet';

import { useAppDispatch } from '../../../store/hooks';
import { changeCurrentLayer } from '../domain/store/slice';

interface MapProps {
  collapseItems?: CollapseProps['items'];
  mapHeight?: number | string | undefined;
}

export const Map: React.FC<MapProps> = ({
  mapHeight = undefined,
  collapseItems,
}) => {
  const dispatch = useAppDispatch();

  const items = [
    {
      key: '1',
      label: 'Фильтр',
      children: (
        <div style={{ overflowX: 'scroll', height: '400px' }}>
          {collapseItems?.map((item) => item as React.ReactElement)}
        </div>
      ),
    },
    {
      key: '2',
      label: 'Легенды',
      children: <LegendMap />,
    },
  ];

  useEffect(() => {
    const map = L.map('map', {
      zoomControl: false,
      attributionControl: false,
      minZoom: 7,
    }).setView([41.2044, 74.7661], 7);

    dispatch(changeCurrentLayer(map));

    L.Icon.Default.imagePath = 'https://unpkg.com/leaflet/dist/images/';

    L.tileLayer(
      'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png',
      {},
    ).addTo(map);

    return () => {
      map.remove();
    };
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      {collapseItems?.length ? (
        <div
          style={{
            padding: 15,
            width: '280px',
            background: 'white',
            margin: '2px',
            zIndex: 10000,
            position: 'absolute',
            overflow: 'hidden',
            minHeight: '400px',
          }}
        >
          <Tabs defaultActiveKey="1" items={items} />
        </div>
      ) : null}
      <MapGeoDataController />
      <div id="map" style={{ height: mapHeight || '500px', width: '100%' }} />
    </div>
  );
};
