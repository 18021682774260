import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Badge,
  Button,
  Col,
  DatePicker,
  Layout,
  Row,
  Segmented,
  Space,
  Typography,
} from 'antd';

import iconShadowLeaflet from '@assets/icons/map/iconShadowLeaflet.png';
import dayjsCustom from '@config/configuredDayjs';
import { ILayer, OppStatusEnum } from '@modules/Map/domain/interface/interface';
import { setMarkerIcon } from '@modules/Map/domain/store/constans';
import { MapStateSelector } from '@modules/Map/domain/store/selector';
import {
  changeCurrentGeoData,
  changeOnLayer,
  getGeoData,
  getLayers,
  removeOnLayer,
  resetOnLayer,
} from '@modules/Map/domain/store/slice';
import { Map as UiMap } from '@modules/Map/ui/Map';
import { getOptions, mapSelectItems } from '@modules/monitoring/contants';
import { SectionTitle } from '@modules/sectionTitle';
import dayjs, { Dayjs } from 'dayjs';
import L from 'leaflet';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';

interface MapLayersProps {
  mapHeight?: string | number;
}

export const MapLayers: React.FC<MapLayersProps> = ({ mapHeight }) => {
  const [oppLayers, setOppLayers] = useState<any[]>(mapSelectItems);
  const { t } = useTranslation();

  const [reset, setReset] = useState(false);

  const [filterDate, setFilterDate] = useState<
    Record<string, undefined | string>
  >({
    from: undefined,
    to: undefined,
  });

  const dispatch = useAppDispatch();
  const { layers, onLayer, currentLayer, mapCounts } =
    useAppSelector(MapStateSelector);

  const countLayer: number | null = useMemo(() => {
    if (!onLayer) return 0;

    return Object.values(onLayer).reduce((acc, value) => {
      if (typeof value === 'object' && value.count) {
        return acc + value.count;
      }
      return acc;
    }, 0);
  }, [onLayer]);

  const handleDateChange = (
    date: Dayjs,
    dateString: string | string[],
    from: string,
  ) => {
    const validDate = date?.format('YYYY/MM/DD');

    setFilterDate((prev) => ({ ...prev, [from]: validDate }));
    setReset(true);
  };

  const onEachFeature = (feature: any, layer: L.Layer) => {
    if (typeof feature?.properties === 'undefined') return;

    const mapData = feature?.properties;

    layer.bindPopup(() => {
      dispatch(
        changeCurrentGeoData({
          ...feature,
        }),
      );

      return `<p>${mapData?.type?.title ?? 'Неизвестно'}</p>`;
    });
  };

  const paintGeoJson = (id: string, data: any) => {
    if (!currentLayer) return;

    const geoData = data.map((item: any) => item.geo);

    const customIcon = (type: string) => {
      return L.icon({
        iconUrl: setMarkerIcon(type),
        shadowUrl: iconShadowLeaflet,
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        popupAnchor: [0, -15],
      });
    };

    const markersBar = L.markerClusterGroup({ showCoverageOnHover: false });

    const layer = L.geoJSON(geoData, {
      filter: (feature) => {
        return feature.geometry.type === 'Point';
      },
      onEachFeature,
      pointToLayer: (f, latlng) => {
        return L.marker(latlng, {
          icon: customIcon(f?.properties?.type?.title),
        });
      },
    });

    markersBar.addLayer(layer);
    currentLayer.addLayer(markersBar);

    markersBar.bindTooltip((mapLayer: any) => {
      return `<p>${
        mapLayer?.feature?.properties?.type?.title ?? 'Неизвестно'
      }<p>${
        mapLayer?.feature?.properties?.incidentDate
          ? dayjs(mapLayer?.feature?.properties?.incidentDate).format(
              'YYYY-MM-DD hh:mm',
            )
          : 'Неизвестно'
      }</p>
        <p>${mapLayer?.feature?.properties?.region?.title || ''}</p>
        <p>${mapLayer?.feature?.properties?.district?.title || ''}</p>`;
    });

    const layerOne = (layers as { id: string; type: string }[]).find(
      (item) => item.id === id,
    );

    dispatch(
      changeOnLayer({
        [id]: {
          layer,
          type: layerOne ? layerOne.type : 'region',
          count: data.length,
          cluster: markersBar,
        },
      }),
    );
  };

  const checkboxOnClick = (id: string) => {
    if (!currentLayer) return;

    const findLayer = (layers as any).find((item: any) => item.id === id);

    if (onLayer[id] === true) {
      /* empty */
    } else if (onLayer[id]) {
      setFilterDate({ to: undefined, from: undefined });
      onLayer[id].cluster.clearLayers();
      currentLayer.removeLayer(onLayer[id].layer);
      dispatch(removeOnLayer(id));
    } else {
      const type = findLayer.type === 'region' ? findLayer.type : 'opp_type';

      dispatch(changeOnLayer({ [id]: true }));
      dispatch(
        getGeoData({
          type,
          id: findLayer.id,
          status: OppStatusEnum.mapPublished,
        }),
      ).then((data: any) => {
        if (data.payload.length) {
          paintGeoJson(id, data.payload);
        } else {
          dispatch(removeOnLayer(id));
        }
      });
    }
  };

  const handleSegmentChange = async (key: string) => {
    if (!currentLayer) return;
    setFilterDate({ to: undefined, from: undefined });
    setReset(false);
    if (onLayer) {
      Object.keys(onLayer).forEach((id: string) => {
        if (onLayer[id]) {
          checkboxOnClick(id);
        }
      });
    }
    const { payload } = await dispatch(getLayers({ type: key }));

    const promises = payload.map((item: ILayer) =>
      dispatch(
        getGeoData({
          type: item.type,
          id: item.id,
          status: OppStatusEnum.mapPublished,
        }),
      ).then((data: any) => {
        if (data.payload.length === 0) return null;
        paintGeoJson(item.id, data.payload);
        return data.payload.length;
      }),
    );

    await Promise.all(promises);
  };

  const validOppLayers: { label: string; value: string }[] = useMemo(() => {
    if ((layers as any)?.length)
      return (layers as any).map((item: any) => ({
        label: item.title,
        value: item.id,
      }));

    return [];
  }, [layers]);

  useEffect(() => {
    const oppOptions = validOppLayers.map((opp) => (
      <Button
        style={{
          whiteSpace: 'normal',
          height: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        className={onLayer[opp.value] ? 'mapDefault active' : 'mapDefault'}
        onClick={() => checkboxOnClick(opp.value)}
        key={opp.value}
      >
        {opp.label}
        {onLayer[opp.value] && (
          <Badge
            overflowCount={onLayer[opp.value]?.count || 0}
            showZero
            count={onLayer[opp.value]?.count || 0}
            color="#faad14"
          />
        )}
      </Button>
    ));

    setOppLayers(oppOptions);
  }, [layers, onLayer]);

  useEffect(() => {
    dispatch(resetOnLayer());
    handleSegmentChange('region').catch(console.error);
  }, [dispatch, currentLayer]);

  useEffect(() => {
    Object.keys(onLayer).forEach((id) => {
      if (filterDate.from && filterDate.to) {
        currentLayer?.removeLayer(onLayer[id].layer);
        currentLayer?.removeLayer(onLayer[id].cluster);
        if (onLayer[id] !== true) {
          dispatch(
            getGeoData({
              type: onLayer[id].type,
              id,
              date: filterDate,
              status: OppStatusEnum.mapPublished,
            }),
          ).then((data: any) => {
            paintGeoJson(id, data.payload);
          });
        }
      }
    });
  }, [filterDate]);

  const resetFilters = () => {
    setFilterDate({ from: undefined, to: undefined });
    setReset(false);
    Object.keys(onLayer).forEach((id) => {
      currentLayer?.removeLayer(onLayer[id].layer);
      dispatch(removeOnLayer(id));
    });
    dispatch(resetOnLayer());
    handleSegmentChange('region').catch(console.error);
  };

  return (
    <div className="p-2">
      <Segmented
        options={getOptions(t)}
        block
        size="large"
        onChange={handleSegmentChange}
      />
      <Row style={{ margin: '20px 0' }} gutter={[10, 0]}>
        <Col>
          <SectionTitle
            title={t('dangerousNaturalProcesses.title')}
            paddingBottom={10}
          />
          <SectionTitle
            title={`${t('dangerousNaturalProcesses.titleOppCount')}: ${
              countLayer || '0'
            }`}
          />
        </Col>
        <Col flex={1}>
          <Row gutter={[10, 10]} align="middle">
            <Col className="ml-auto">
              <Typography>{t('filtering.title')} </Typography>
            </Col>
            <Col>
              <DatePicker
                disabled={Object.keys(onLayer).length === 0}
                value={filterDate.from ? dayjsCustom(filterDate.from) : null}
                onChange={(date, dateString) => {
                  handleDateChange(date, dateString, 'from');
                }}
                placeholder={t('filtering.from')}
              />
            </Col>
            <Col>
              <DatePicker
                disabled={Object.keys(onLayer).length === 0}
                value={filterDate.to ? dayjsCustom(filterDate.to) : null}
                onChange={(date, dateString) => {
                  handleDateChange(date, dateString, 'to');
                }}
                placeholder={t('filtering.to')}
              />
            </Col>
            {reset && (
              <Col>
                <Button onClick={resetFilters}>{t('form.reset')}</Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Layout style={{ borderRadius: 15 }}>
        <UiMap mapHeight={mapHeight || '95vh'} collapseItems={oppLayers} />
      </Layout>
      <Space size="large" direction="vertical">
        <Row gutter={6}>
          {mapCounts.map((item: any) => (
            <Col key={item.id}>
              <Typography.Text>{item.title}</Typography.Text>
              <Typography.Text>{item.count}</Typography.Text>
            </Col>
          ))}
        </Row>
      </Space>
    </div>
  );
};
