import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Select } from 'antd';

import { SectionTitle } from '@modules/sectionTitle';
// eslint-disable-next-line import/no-extraneous-dependencies
import cn from 'classnames';

import styles from './styles.module.scss';

const dateChoice = [
  { label: 'Сейчас', value: 'now/' },
  { label: 'Завтра', value: 'tomorrow/' },
  { label: 'Через 3 дня', value: '3-days/' },
  { label: 'Через 10 дней', value: '10-days/' },
  { label: 'Через 2 недели', value: '2-weeks/' },
  { label: 'Через месяц', value: 'month/' },
];

const options = [
  { label: 'Бишкек', value: 'weather-bishkek-5327/' },
  { label: 'Балыкчы', value: 'weather-balykchy-5208/' },
  { label: 'Баткен', value: 'weather-batken-5340/' },
  { label: 'Джалал-Абад', value: 'weather-dzhalal-abad-5343/' },
  { label: 'Кара-Балта', value: 'weather-karabalta-5322/' },
  { label: 'Кара-Суу', value: 'weather-kara-suu-328837/' },
  { label: 'Каракол', value: 'weather-karakol-5209/' },
  { label: 'Кемин', value: 'weather-kemin-328838/' },
  { label: 'Кочкор-Ата', value: 'weather-kochkor-ata-328841/' },
  { label: 'Манас', value: 'weather-manas-14399/' },
  { label: 'Нарын', value: 'weather-naryn-5210/' },
  { label: 'Ноокат', value: 'weather-nookat-328844/' },
  { label: 'Ош', value: 'weather-osh-5344/' },
  { label: 'Раззаков', value: 'weather-razzakov-328832/' },
  { label: 'Талас', value: 'weather-talas-5326/' },
  { label: 'Токмок', value: 'weather-tokmok-5207/' },
  { label: 'Токтогул', value: 'weather-toktogul-328848/' },
  { label: 'Узген', value: 'weather-uzgen-5346/' },
  { label: 'Чолпон-Ата', value: 'weather-cholpon-ata-328849/' },
  { label: 'Шамалды-Сай', value: 'weather-shamaldy-say-328856/' },
];

export const Frame = () => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const [weatherName, setWeatherName] = useState('weather-bishkek-5327/');
  const [dateName, setDateName] = useState('now/');
  const { t } = useTranslation();

  const onChange = (value: string) => {
    setWeatherName(value);
  };

  const onChangeDate = (value: string) => {
    setDateName(value);
  };

  return (
    <>
      <SectionTitle title={t('monitoring.weatherMonitoring')} />
      <iframe
        style={{ margin: '20px 0 150px 0' }}
        title="Windy Weather Map"
        width="1266"
        height="600"
        src="https://embed.windy.com/embed.html?type=map&location=coordinates&metricRain=mm&metricTemp=°C&metricWind=m/s&zoom=5&overlay=wind&product=ecmwf&level=surface&lat=39.91&lon=74.619&detailLat=42.867&detailLon=74.606&detail=true&pressure=true&message=true"
      />
    </>
    // <Row gutter={24} className="mb-[20px]">
    //   <Col
    //       className={cn(styles.iframeBlock, {
    //         [styles.now]: dateName === 'now/',
    //         [styles.tomorow]: dateName === 'tomorrow/',
    //         [styles.days_3]: dateName === '3-days/',
    //         [styles.days_10]: dateName === '10-days/' || dateName === '2-weeks/',
    //         [styles.month]: dateName === 'month/',
    //       })}
    //     >
    //        <iframe
    //         className={styles.iframeClass}
    //         src={`https://www.gismeteo.ru/${weatherName}${dateName}`}
    //         title="Прогноз погоды для Бишкека"
    //         scrolling="no"
    //        />
    //     </Col>
    //
    //     <Col>
    //       <Row gutter={[6, 12]}>
    //         <Col span={24}>
    //           <Select
    //             options={options}
    //             defaultValue="Бишкек"
    //             onChange={(value) => onChange(value)}
    //           />
    //         </Col>
    //         <Col span={24}>
    //           <Select
    //             defaultValue="Сейчас"
    //             options={dateChoice}
    //             onChange={(value) => onChangeDate(value)}
    //           />
    //         </Col>
    //       </Row>
    //     </Col>
    //   </Row>
  );
};
